import React from 'react'
import abt from '../../images/about.webp'
import img from '../../images/abt1.webp'
import { useMediaQuery } from 'react-responsive'

const About = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className="about-section" id="about-section">
            <div className="container">
                {!isMobile && 
                    <div className='space-60'></div>
                }
                
                <div className='row'>
                    <div className='col-lg-6 col-md-12 d-flex align-items-center' style={{ borderRight: !isMobile ? '1px solid #CDD5A2' : "" }}>
                        <div className="about-card">
                            <div className="about-content">
                                <h1 className='text-uppercase'>Welcome to the square</h1>
                                <p>It's an intriguing invitation to explore a new dimension of luxury living, where boundaries are pushed and expectations exceeded. Experience a cosmopolitan community at “The Square”.</p>
                                <img src={img} alt="" width="150"/>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className="about-image" >
                            
                            <img src={abt} alt="about" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
