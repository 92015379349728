import React from 'react'
import img from '../../images/join.webp'
import { FaArrowRightLong } from "react-icons/fa6";
import {NavLink} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';

const Join = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <>
    <div className='position-relative join-div'>
      {
        !isMobile && 
        <img  src={img} alt="" className='img-fluid'></img>
      }
      
      <div className='join-section'>
        <div className='container '>
          <div className='row'>
              <div className={`col-lg-6 col-md-12 d-flex align-items-center ${isMobile ? 'justify-content-center' : 'justify-content-start'}`}>
                  <div className='join-text'>
                      <h1 className='text-uppercase'>Join Us at The square</h1>
                      <p>Come, join and discover the essence of vibrant living at The Square, where luxury harmonizes with nature. Begin your journey towards a redefined lifestyle.</p>
                      <div>
                          <button className='schedule-button'><NavLink to="https://wa.me/9121777777" target="_blank">Schedule a Site Visit <FaArrowRightLong /></NavLink></button>{isMobile && <br/>}
                          <button className='contact-button'><NavLink to="tel:9121777777" target="_blank">Contact Us <FaArrowRightLong /></NavLink></button>
                      </div>
                  </div>
              </div>
              <div className='col-lg-6 col-md-12'>
                  
              </div>
          </div>
        </div>
      </div>
      
    </div>
    </>
  )
}

export default Join
