import React from 'react'
import bg1 from '../../images/location_background1.webp'
import bg2 from '../../images/location_background2.webp'
import loc from '../../images/location.webp'
import edu from '../../images/education.webp'
import img from '../../images/workPlace.webp'
import connect from '../../images/connectivity.webp'
import map from '../../images/location-map1.png'

const Location = () => {
    const education=[
        {
            "id": 1,
            "location": "Sri Gayatri Jr College",
            "duration": "04 Mins"
        },
        {
            "id": 2,
            "location": "Narayana Jr College",
            "duration": "08 Mins"
        },
        {
            "id": 3,
            "location": "Sloka Intl. School ",
            "duration": "11 Mins"
        },
        {
            "id": 4,
            "location": "TISS University",
            "duration": "15 Mins"
        },
        {
            "id": 5,
            "location": "Delhi Public School, Nadergul",
            "duration": "17 Mins"
        },
        {
            "id": 6,
            "location": "Aga Khan Academy ",
            "duration": "20 Mins"
        },
        {
            "id": 7,
            "location": "20 Engg. Colleges nearby",
            "duration": "20 Mins"
        },
        {
            "id": 8,
            "location": "Manchester Global School",
            "duration": "25 Mins"
        },
        {
            "id": 9,
            "location": "GMR School of Business",
            "duration": "27 Mins"
        },
        {
            "id": 10,
            "location": "GMR Chinmaya Vidyalaya",
            "duration": "30 Mins"
        },
    ];

    const work=[
        {
            "id": 1,
            "location": "Adibatla Aerospace SEZ",
            "duration": "08 Mins"
        },
        {
            "id": 2,
            "location": "Foxconn Facility, Kongara Kalan",
            "duration": "08 Mins"
        },
        {
            "id": 3,
            "location": "TCS Campus, Adibatla",
            "duration": "10 Mins"
        },
        {
            "id": 4,
            "location": "Tata Aerospace",
            "duration": "10 Mins"
        },
        {
            "id": 5,
            "location": "Tata Sikorsky",
            "duration": "13 Mins"
        },
        {
            "id": 6,
            "location": "Fab City",
            "duration": "15 Mins"
        },
        {
            "id": 7,
            "location": "RCI / ARCI",
            "duration": "20 Mins"
        },
        {
            "id": 8,
            "location": "Hardware Park",
            "duration": "20 Mins"
        },
        {
            "id": 9,
            "location": "Rajiv Gandhi Intl. Airport ",
            "duration": "25 Mins"
        },
        {
            "id": 10,
            "location": "Proposed Pharma City",
            "duration": "35 Mins"
        },
    ];

    const connectivity=[
        {
            "id": 1,
            "location": "Bonguluru ORR Exit No 12",
            "duration": "10 Mins"
        },
        {
            "id": 2,
            "location": "ORR Exit No 14",
            "duration": "11 Mins"
        },
        {
            "id": 3,
            "location": "Tukkuguda",
            "duration": "12 Mins"
        },
        {
            "id": 4,
            "location": "Ibrahimpatnam",
            "duration": "18 Mins"
        },
        {
            "id": 5,
            "location": "Srisailam Highway",
            "duration": "19 Mins"
        },
        {
            "id": 6,
            "location": "LB Nagar Circle",
            "duration": "30 Mins"
        },
        {
            "id": 7,
            "location": "DRDO Apollo Hospital",
            "duration": "35 Mins"
        },
        {
            "id": 8,
            "location": "Gachibowli / Financial District",
            "duration": "50 Mins"
        }
    ];
  return (
    <>
        <div className='location' style={{backgroundImage: `url(${bg2})`}}>
            <div className='container'>
                <div className='row location-img-section' >
                    <div className='col-12'>
                        <div className='location-img'>
                            <img src={loc} alt="" className='img-fluid'></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='location ' style={{backgroundImage: `url(${bg1})`}}>
            
            <div className='container position-relative'>
                <img src={map} className='img-fluid position-absolute' alt="" style={{width: '90%', left: '11%'}}></img>
                <div className='prime-location position-relative'>
                    <div className='text-center'>
                        <h1>Prime Location</h1>
                        <div className='space-10'></div>
                        <p>Imagine living in a global address at Hyderabad’s South, as a bold new frontier is expanding fast with enviable infrastructure. Adibatla is becoming an eminently desirable place to settle down.</p>
                    </div>
                    <div className="space-60"></div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                            <div className='location-duration'>
                                <img src={edu} alt=""></img>
                                <h5>EDUCATIONAL INSTITUTIONS</h5>
                                {
                                    education.map((ele, i)=>{
                                        return(
                                            <div className='d-flex justify-content-between' key={i}>
                                                <span>{ele.location}</span> 
                                                <span className='duration'>{ele.duration}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <div className='location-duration'>
                                <img src={img} alt=""></img>
                                <h5>WORK PLACES</h5>
                                <div className='space-10'></div>
                                {
                                    work.map((ele, i)=>{
                                        return(
                                            <div className='d-flex justify-content-between' key={i}>
                                                <span>{ele.location}</span> 
                                                <span className='duration'>{ele.duration}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <div className='location-duration'>
                                <img src={connect} alt=""></img>
                                <div className='space-10'></div>
                                <h5>CONNECTIVITY</h5>
                                <div className='space-10'></div>
                                {
                                    connectivity.map((ele, i)=>{
                                        return(
                                            <div className='d-flex justify-content-between' key={i}>
                                                <span>{ele.location}</span> 
                                                <span className='duration'>{ele.duration}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    
  )
}

export default Location
