import React from 'react'
import { FaWhatsapp } from "react-icons/fa";

const floatingComponent = () => {
  return (
    <>
        
        <a href="https://wa.me/9121777777" class="float" target="_blank">
            <FaWhatsapp className='my-float'/>
        </a>
    </>
  )
}

export default floatingComponent
