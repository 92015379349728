import React, {useState, useEffect} from 'react'
import logo from '../../images/favicon.png'
import { IoIosArrowRoundForward } from "react-icons/io";
import {Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import logo2 from '../../images/ira-logo.webp'

const DesktopHeader = () => {
    const [navSolid, setNavSolid] = useState(false);

    const handleScroll = () => {
        if(window.scrollY > 75){
        setNavSolid(true);
        }else{
        setNavSolid(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="header">
            <Navbar collapseOnSelect expand="lg" >
                <Container>
                    
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <div className='row w-100'>
                            <div className='col-lg-4 d-flex align-items-center'>
                                <div className='d-flex align-items-center w-100'>
                                    {/* <div className='ira-heading-head'><h1>IRA </h1></div><div className='ira-celebrate'><span>Celebrating the <br/> year of IRA</span></div> */}
                                    <Navbar.Brand href="#home"><img src={logo2} alt="" title="" /></Navbar.Brand>
                                </div>
                            </div>
                            <div className='col-lg-4 text-center' >
                                <Navbar.Brand href="#home"><img src={logo} alt="" title="" /></Navbar.Brand>
                            </div>
                            <div className='col-lg-4 d-flex align-items-center'>
                                <div className='text-end w-100'>
                                    <a href="https://wa.me/9121777777" target="_blank" className="btn btn-enquiry">Enquire Now <IoIosArrowRoundForward size={30} /></a>
                                </div>
                            </div>
                        </div>
                   
                    
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default DesktopHeader
