import React from 'react'
import img1 from '../../images/highlight.webp'
import { useMediaQuery } from 'react-responsive'

const ProjectHighlight = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  return (
    <>
      {/* <div className="space-20"></div> */}
      <div className='highlight-image'>
        <img src={img1} alt="" className='img-fluid'></img>
        <div className='headling'>
          <h1>Project Highlights</h1>
        </div>
        <div className='highlights'>
          {isMobile &&
            <div className="space-40"></div>
          }
          <ul>
            <li>HMDA & RERA Approved Luxury Villa Project</li>
            <li>13.5 Acres of land</li>
            <li>Majestic Entrance Gate</li>
            <li>131 East & West facing Duplex Villas</li>
            <li>Plot sizes: 267, 300, 330 Sq Yd</li>
            <li>Built-up area: 3700 Sft for all Villas</li>
            <li>Provision for Home Automation</li>
            <li>Two Car Parking spaces for each Villa</li>
            <li>40' & 30' Wide Internal BT Roads</li>
            <li>100% Vastu</li>
            <li>Avenue Plantation, Street Lighting, Pedestrian Path, Jogging Track</li>
            <li>24-hr Water Supply, Parks & Play areas, Designer Landscaping</li>
            <li>24X7 Power Back-up</li>
          </ul>
          {isMobile &&
            <div className="space-40"></div>
          }
        </div>
      </div>
      
    </>
  )
}

export default ProjectHighlight
