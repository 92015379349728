import './App.css';
import Approuters from './routers/Approuters';

function App() {
  return (
    <>
      <Approuters />
    </>
  );
}

export default App;
