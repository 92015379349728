import React, { Suspense } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ScrollToTop } from '../hooks'
import * as view from '../views'
import * as ROUTES from '../constant/routes'
import * as layout from '../component/layout'

const Approuters = () => {
  return (
    <Router>
        <Suspense>
            <Routes>
                <Route path={ROUTES.Home} element={
                    <ScrollToTop>
                        <layout.DesktopLayout>
                            <view.Home />
                        </layout.DesktopLayout>
                    </ScrollToTop>
                } />
            </Routes>
        </Suspense>
    </Router>
            
  )
}

export default Approuters
