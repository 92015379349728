import React from 'react'
import community from '../../images/community.webp'

const AmenitiesBanner = () => {
  return (
    <>
      <div className='square-image'>
            <img src={community} alt="" className='img-fluid'></img>
            <div className="space-20"></div>
            <div>
                <h1 className='text-uppercase'>A Community that Resonates  with Your Lifestyle</h1>
                <p>The Square is a serene sanctuary in an evolving global neighbourhood. From the finest finishes to the most innovative amenities, you will find everything you need to make your dream lifestyle a reality</p>
            </div>         
        </div>
        
    </>
  )
}

export default AmenitiesBanner
