import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay } from 'swiper/modules';
import slider1 from '../../images/slider1.webp'
import slider2 from '../../images/slider2.webp'
import slider3 from '../../images/slider3.webp'
import slider4 from '../../images/slider4.webp'
import mobsli1 from '../../images/mobSlider1.webp'
import mobsli2 from '../../images/mobSlider2.webp'
import mobsli3 from '../../images/mobSlider3.webp'
import mobsli4 from '../../images/mobSlider4.webp'
import logo from '../../images/favicon.png'
import { IoIosArrowRoundForward } from "react-icons/io";

import { useMediaQuery } from 'react-responsive';


const Slider = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const data=[
    {
      "id": 1,
      "img": slider1,
      "mobimg": mobsli1,
    },
    {
      "id": 2,
      "img": slider2,
      "mobimg": mobsli2,
    },
    {
      "id": 3,
      "img": slider3,
      "mobimg": mobsli3,
    },
    {
      "id": 4,
      "img": slider4,
      "mobimg": mobsli4,
    }
  ];
  return (
    <>
      
      <div id="slider-section" style={{marginTop: '-15%'}}>
        {isMobile &&
          <img src={logo} alt="" className='slider-logo'></img>
        }
        <Swiper 
          // pagination={{
          //   clickable: true,
          // }} 
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          loop={true} 
          speed={1000}
          className="mySwiper">
          {
            data.map((ele, i)=>{
              return(
                <SwiperSlide key={i}>
                  <div className='swiper-section'>
                    {
                      isMobile ?
                      <img src={ele.mobimg} alt={ele.mobimg}></img>
                      :
                      <img src={ele.img} alt={ele.img}></img>
                    }
                    
                    
                  </div>  
                  </SwiperSlide>
                  
              )
            })
          }
        </Swiper>
        <div className='slider-text'>
          {!isMobile ? 
            <>
              <h1>Discover a New Dimension of Luxury</h1>
              <h1>Living at IRA Square</h1>
            </>
            :
            <h1>Discover a New Dimension of Luxury Living at IRA Square</h1>
          }
          <p>Luxurious Villas in Adibatla, Hyderabad</p>
          <a href="#" className="btn btn-enquiry">Schedule a Site Visit <IoIosArrowRoundForward size={30} /></a>
        </div>
      </div>
    </>
  )
}

export default Slider
