import React from 'react'
import {DesktopHeader, MobileHeader} from '../common'
import { useMediaQuery } from 'react-responsive'

const Header = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  return (
    <>
    {isMobile ?
      // 
      <></>
      : 
      <DesktopHeader />  
    }
    </>
  )
}

export default Header
